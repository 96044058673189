import './style.css'
import fscreen from 'fscreen'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { Vector2 } from 'three'
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js'

// case to display
let caseNum = 0
const case1 = document.getElementById('case1')
const case2 = document.getElementById('case2')
const case3 = document.getElementById('case3')
const help1 = document.getElementById('help1')

// canvases
let isMobile = null
const main = document.getElementById('div')
const header = document.getElementById('header')
const footer = document.getElementById('footer')
let canvas = document.getElementById('canvas')
const ctxHeader = header.getContext('2d')
const ctxFooter = footer.getContext('2d')
let ctx2d = canvas.getContext('2d')
const canvas3d = document.getElementById('canvas3d')
canvas3d.hidden = true

// buttons
const redlionLogo = document.getElementById('redlionLogo')
const greenlionLogo = document.getElementById('greenlionLogo')
const homeButtonGray = document.getElementById('homeButtonGray')
const homeButtonGreen = document.getElementById('homeButtonGreen')
const traButtonGreen = document.getElementById('traButtonGreen')
const corButtonGreen = document.getElementById('corButtonGreen')
const sagButtonGreen = document.getElementById('sagButtonGreen')
const traButtonGray = document.getElementById('traButtonGray')
const corButtonGray = document.getElementById('corButtonGray')
const sagButtonGray = document.getElementById('sagButtonGray')
const reportButtonGray = document.getElementById('reportButtonGray')
const reportButtonGreen = document.getElementById('reportButtonGreen')
const page2dButtonGray = document.getElementById('2dButtonGray')
const page2dButtonGreen = document.getElementById('2dButtonGreen')
const page3dButtonGray = document.getElementById('3dButtonGray')
const page3dButtonGreen = document.getElementById('3dButtonGreen')
const traButtonWhite = document.getElementById('traButtonWhite')
const corButtonWhite = document.getElementById('corButtonWhite')
const sagButtonWhite = document.getElementById('sagButtonWhite')
const reportButtonWhite = document.getElementById('reportButtonWhite')
const page2dButtonWhite = document.getElementById('2dButtonWhite')
const page3dButtonWhite = document.getElementById('3dButtonWhite')
const playButtonGray = document.getElementById('playButtonGray')
const playButtonGreen = document.getElementById('playButtonGreen')
const stopButtonGray = document.getElementById('stopButtonGray')
const stopButtonGreen = document.getElementById('stopButtonGreen')
const reverseButtonGray = document.getElementById('reverseButtonGray')
const reverseButtonGreen = document.getElementById('reverseButtonGreen')
const flipButtonGray = document.getElementById('flipButtonGray')
const flipButtonGreen = document.getElementById('flipButtonGreen')
const upButtonGray = document.getElementById('upButtonGray')
const upButtonGreen = document.getElementById('upButtonGreen')
const downButtonGray = document.getElementById('downButtonGray')
const downButtonGreen = document.getElementById('downButtonGreen')
const colorbarV = document.getElementById('colorbarV')
const background1 = document.getElementById('background1')
const background2 = document.getElementById('background2')
let bkg = 2

// slices
let tra = null
let cor = null
let sag = null
const tra1 = document.getElementById('tra1')
const sag1 = document.getElementById('sag1')
const cor1 = document.getElementById('cor1')
const tra2 = document.getElementById('tra2')
const sag2 = document.getElementById('sag2')
const cor2 = document.getElementById('cor2')
const tra3 = document.getElementById('tra3')
const sag3 = document.getElementById('sag3')
const cor3 = document.getElementById('cor3')

// slice numbers & cursor locations
let traNum = 22
let traXpos = 0.5
let traYpos = 0.5
let sagNum = 22
let sagXpos = 0.5
let sagYpos = 0.5
let corNum = 22
let corXpos = 0.5
let corYpos = 0.5
let navXpos = 0
let navYpos = 0
function initSlicePositions() {
    traNum = 22
    traXpos = 0.5
    traYpos = 0.5
    sagNum = 22
    sagXpos = 0.5
    sagYpos = 0.5
    corNum = 22
    corXpos = 0.5
    corYpos = 0.5
    navXpos = 0
    navYpos = 0    
}

// viewports & rects
let xs1 = 0 // viewport size 1
let xs2 = 0 // viewport size 2
let quadrant = [0,0,0,0]   // the active 3D quadrant
let mainRect = [0,0,0,0]
let headerRect = [0,0,0,0]
let footerRect = [0,0,0,0]
let canvasRect = [0,0,0,0]
let canvas3dRect = [0,0,0,0]
let traRect = [0,0,0,0]
let sagRect = [0,0,0,0]
let corRect = [0,0,0,0]
let navRect = [0,0,0,0]
let thumbRect = []
let lionRect = [0,0,0,0]
let button0Rect = [0,0,0,0]
let button1Rect = [0,0,0,0]
let button2Rect = [0,0,0,0]
let button3Rect = [0,0,0,0]
let button4Rect = [0,0,0,0]
let button5Rect = [0,0,0,0]
let button6Rect = [0,0,0,0]

// define the rects
function defineAllRects() {
    //
    var width = 0
    var height = 0
    isMobile = false
    // for Mobile devices the sizes are in full screen mode:
    if (window.screen.width == window.innerWidth) {
        isMobile = true
        width = window.screen.width
        height = window.screen.height
    } else {
        isMobile = false
        width = window.innerWidth
        height = window.innerHeight
    }

    // iPad exception
    if (window.screen.width == 768) {
        isMobile = false
        width = window.innerWidth
        height = window.innerHeight
    }

    // top and height are fixed because display is always in portrait mode
    mainRect[1] = 0  
    mainRect[3] = height
    var xs1h = Math.floor(0.82 * height / 3)    // xs1 based on height
    var xs1w = Math.floor(10 * width / 19)      // xs1 based on width
    if (xs1h < xs1w) {
        xs1 = xs1h
        xs2 = Math.floor(0.82 * height / 10)
        mainRect[0] = Math.floor((width - xs1 - 3 * xs2) / 2)
        mainRect[2] = xs1 + 3 * xs2
    } else {
        xs1 = xs1w
        xs2 = Math.floor(3 * width / 19)
        mainRect[0] = 0
        mainRect[2] = width
    }

    // the heights of the 3 sections
    let canvasHeight = 3 * xs1
    let footerHeight = Math.floor(0.40 * (mainRect[3] - canvasHeight))
    let headerHeight = mainRect[3] - canvasHeight - footerHeight

    // main rect
    main.left = mainRect[0]
    main.top = mainRect[1]
    main.width = mainRect[2]
    main.height = mainRect[3]
    main.style.left = (main.left).toString() + 'px' 
    main.style.top = (main.top).toString() + 'px' 
    main.style.width = (main.width).toString() + 'px'
    main.style.height = (main.height).toString() + 'px'
    main.style.backgroundColor = 'rgb(0, 0, 0)'
    main.style.position = 'fixed'

    // headerRect
    headerRect[0] = mainRect[0]
    headerRect[1] = 0
    headerRect[2] = mainRect[2]
    headerRect[3] = headerHeight  
    header.left = headerRect[0]
    header.top = headerRect[1]
    header.width = headerRect[2]
    header.height = headerRect[3]
    header.style.left = (header.left).toString() + 'px' 
    header.style.top = (header.top).toString() + 'px'
    header.style.width = (header.width).toString() + 'px'
    header.style.height = (header.height).toString() + 'px'
    header.style.backgroundColor = 'rgb(0, 0, 0)' 

    // canvasRect
    canvasRect[0] = mainRect[0]
    canvasRect[1] = headerHeight
    canvasRect[2] = mainRect[2]
    canvasRect[3] = canvasHeight
    canvas.left = canvasRect[0]
    canvas.top = canvasRect[1]
    canvas.width = canvasRect[2]
    canvas.height = canvasRect[3]
    canvas.style.left = (canvas.left).toString() + 'px' 
    canvas.style.top = (canvas.top).toString() + 'px' 
    canvas.style.width = (canvas.width).toString() + 'px'
    canvas.style.height = (canvas.height).toString() + 'px'
    canvas.style.backgroundColor = 'rgb(0, 0, 0)'

    // footerRect
    footer.style.backgroundColor = 'rgb(0, 0, 0)' 
    footer.style.opactity = '1.0'
    footerRect[0] = canvasRect[0]
    footerRect[1] = canvasRect[1] + canvasRect[3]
    footerRect[2] = canvasRect[2]
    footerRect[3] = mainRect[3] - canvasRect[3] - headerRect[3]
    footer.left = footerRect[0]
    footer.top = footerRect[1]
    footer.width = footerRect[2]
    footer.height = footerRect[3]
    footer.style.left = (footer.left).toString() + 'px' 
    footer.style.top = (footer.top).toString() + 'px'
    footer.style.width = (footer.width).toString() + 'px'
    footer.style.height = (footer.height).toString() + 'px' 

    // define the slice rects (relative to canvas)
    traRect = [0, 0, xs1, xs1]
    corRect = [0, xs1, xs1, xs1]
    sagRect = [0, 2 * xs1, xs1, xs1]
    navRect = [canvasRect[2] - 3 * xs2, 0, 3 * xs2, 10 * xs2]
    for (var i = 0; i < 30; i++) {
        var col = navRect[0] + xs2 * Math.floor(i / 10)
        var row = navRect[1] + xs2 * (i % 10)
        thumbRect[i] = [col, row, xs2, xs2]
    }

    // define the button rects
    if (footerRect[3] >= 66) {
        var buttonSize = 64
        var yoffset = Math.floor((footerRect[3] - buttonSize) / 2)
    } else {
        var buttonSize = footerRect[3] - 2
        var yoffset = 1
    }
    var xspacing = (xs2 - buttonSize) / 2
    //
    button0Rect[0] = Math.floor(xspacing)
    button0Rect[1] = 1
    button0Rect[2] = buttonSize
    button0Rect[3] = buttonSize
    //
    button1Rect[0] = Math.floor(xspacing)
    button1Rect[1] = yoffset
    button1Rect[2] = buttonSize
    button1Rect[3] = buttonSize
    //
    button2Rect[0] = Math.floor(2 * xspacing + buttonSize)
    button2Rect[1] = yoffset
    button2Rect[2] = buttonSize
    button2Rect[3] = buttonSize 
    //
    button3Rect[0] = Math.floor(3 * xspacing + 2 * buttonSize)
    button3Rect[1] = yoffset
    button3Rect[2] = buttonSize
    button3Rect[3] = buttonSize 
    //
    button4Rect[0] = Math.floor(mainRect[2] - 3 * xs2 + xspacing)
    button4Rect[1] = yoffset
    button4Rect[2] = buttonSize
    button4Rect[3] = buttonSize 
    //
    button5Rect[0] = Math.floor(mainRect[2] - 2 * xs2 + xspacing)
    button5Rect[1] = yoffset
    button5Rect[2] = buttonSize
    button5Rect[3] = buttonSize 
    //
    button6Rect[0] = Math.floor(mainRect[2] - xs2 + xspacing)
    button6Rect[1] = yoffset
    button6Rect[2] = buttonSize
    button6Rect[3] = buttonSize
    
    // define lion rect in header
    var lionSize = Math.floor(0.65 * headerHeight)
    if (lionSize > 64 ) { lionSize = 64 }
    lionRect[0] = Math.floor(headerRect[2] / 2 - Math.floor(1.10 * lionSize) / 2)
    lionRect[1] = Math.floor(0.70 * headerHeight - lionSize)
    lionRect[2] = Math.floor(1.10 * lionSize)
    lionRect[3] = lionSize

    // canvas3d
    canvas3dRect[0] = mainRect[0]
    canvas3dRect[1] = Math.floor((mainRect[3] - mainRect[2]) / 2)
    canvas3dRect[2] = mainRect[2]
    canvas3dRect[3] = mainRect[2]
    canvas3d.left = canvas3dRect[0]
    canvas3d.top = canvas3dRect[1]
    canvas3d.width = canvas3dRect[2]
    canvas3d.height = canvas3dRect[3]
    canvas3d.style.left = (canvas3d.left).toString() + 'px' 
    canvas3d.style.top = (canvas3d.top).toString() + 'px' 
    canvas3d.style.width = (canvas3d.width).toString() + 'px'
    canvas3d.style.height = (canvas3d.height).toString() + 'px'
    canvas3d.style.backgroundColor = 'rgb(0, 0, 0)'

}

// threejs
const loadingManager = new THREE.LoadingManager()
const gltfLoader = new GLTFLoader(loadingManager)
const scene = new THREE.Scene()
let ambientLight = new THREE.AmbientLight(0xffffff, 1.0)
scene.add(ambientLight)
let spotLight = new THREE.SpotLight(0xffffff, 1.0)
spotLight.position.set( 4, 4, 4);
spotLight.angle = Math.PI / 2
scene.add(spotLight)
let camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0.1, 100)
scene.add, camera
camera.position.z = 3.0
camera.position.y = 0
camera.position.x = 0
camera.lookAt(new THREE.Vector3( 0, 0, 0 ))
camera.updateProjectionMatrix()
camera.layers.set = 0
spotLight.position.copy(camera.position)
let renderer = new THREE.WebGLRenderer({ canvas: canvas3d })
let texture1 = null
let texture2 = null

// view labels
const pagetitle = document.getElementById('pagetitle')
const q1text = document.getElementById('q1text')
const q2text = document.getElementById('q2text')
const q3text = document.getElementById('q3text')
const q4text = document.getElementById('q4text')
let q1rect = [0,0,0,0]
let q2rect = [0,0,0,0]
let q3rect = [0,0,0,0]
let q4rect = [0,0,0,0]

// brain mesh data
let meshLoaded = false
let brainWireframe = null
let brainMTV50 = null
let brainMTV57 = null
let brainMTV67 = null
let brainMTV85 = null
let brainMTV90 = null
let brainCortex = null

// mtv colors
let wireframeColor = 0x3333ff
let mtv50Color = 0x888888
let mtv57Color = 0x888888
let mtv67Color = 0x888888
let mtv85Color = 0x880000
let mtv90Color = 0x888888

let cortexMaterial = null
let wireframeMaterial = null
let mtv50Material = null
let mtv57Material = null
let mtv67Material = null
let mtv85Material = null
let mtv90Material = null
createMeshMaterials()

// event variables
let mouseDown = false
let mouseX = 0
let mouseY = 0
let deltaX = 0
let deltaY = 0
let clickTimer = null

// app variables
let page = 0
let mode = 'init'
let cineMode = true
let cineZrotation = true
let rotationSpeed = 0.01
let zoomMode = 0 // combo display
let stopTick = true

// pdf report
let pdfState = {
    pdf: null,
    currentPage: 1,
    zoom: 1,
    offsetX: 0,
    offsetY: 0,
    incOffsetY: 0,
    maxOffsetY: 0
}

window.onload = (ev) => { 
    console.log('all image data now loaded')
    // define the rects
    defineAllRects()
    initSlicePositions()
    // console
    console.log('isMobile:', isMobile)
    console.log('screen size:', screen.width, screen.height)
    console.log('window size:', window.innerWidth, window.innerHeight)
    console.log('mainRect: ', mainRect)
    console.log('headerRect: ', headerRect)
    console.log('canvasRect: ', canvasRect)
    console.log('canvas3dRect: ', canvas3dRect)   
    console.log('footerRect: ', footerRect) 
    console.log('traRect: ', traRect) 
    console.log('corRect: ', corRect) 
    console.log('sagRect: ', sagRect) 
    console.log('navRect: ', navRect)
    console.log('lionRect:', lionRect) 
    console.log('xs1/xs2: ', xs1, xs2) 
    // background image
    if (bkg == 1) { document.body.style.backgroundImage = "url('/img/background1.jpg')" }
    if (bkg == 2) { document.body.style.backgroundImage = "url('/img/background2.jpg')" }
    // let's go!
    createPage1()
}

case1.addEventListener('click', (ev) => {
    // display this case
    caseNum = 1
    tra = tra1
    sag = sag1
    cor = cor1
    // load the mesh data for this case
    meshLoaded = false
    loadBrainMeshData()
    loadingManager.onLoad = () => { 
        console.log('all mesh data now loaded')
        meshLoaded = true 
    }
    // if mobile then go fullscreen
    if ((isMobile) && (fscreen.fullscreenElement == null)) { 
        fscreen.requestFullscreen(main) 
    } else {
        if (isMobile) { fscreen.exitFullscreen(main) }
    }
    // create triangulation page
    createPage2()
})

case2.addEventListener('click', (ev) => {
    // display this case
    caseNum = 2
    tra = tra2
    sag = sag2
    cor = cor2
    // load the mesh data for this case
    meshLoaded = false
    loadBrainMeshData()
    loadingManager.onLoad = () => { 
        console.log('all mesh data now loaded')
        meshLoaded = true 
    }
    // if mobile then go fullscreen
    if ((isMobile) && (fscreen.fullscreenElement == null)) { 
        fscreen.requestFullscreen(main) 
    } else {
        if (isMobile) { fscreen.exitFullscreen(main) }
    }
    // create triangulation page
    createPage2()
})

case3.addEventListener('click', (ev) => {
    // display this case
    caseNum = 3
    tra = tra3
    sag = sag3
    cor = cor3
    // load the mesh data for this case
    meshLoaded = false
    loadBrainMeshData()
    loadingManager.onLoad = () => { 
        console.log('all mesh data now loaded')
        meshLoaded = true 
    }
    // if mobile then go fullscreen
    if ((isMobile) && (fscreen.fullscreenElement == null)) { 
        fscreen.requestFullscreen(main) 
    } else {
        if (isMobile) { fscreen.exitFullscreen(main) }
    }
    // create triangulation page
    createPage2()
})

canvas.addEventListener('touchstart', (ev) => {
    console.log('touchstart', ev)
    mouseX = ev.touches[0].clientX
    mouseY = ev.touches[0].clientY
    if (mode == 'init') { 
        // ignore 'touchstart' when mode is 'init'
        return
    } else {
        ev.preventDefault()
        mouseDown = true
    }
})

canvas.addEventListener('touchmove', (ev) => {    
    //
    if (page == 2) {
        if (mouseDown == false) { return }
        mouseX = ev.touches[0].clientX
        mouseY = ev.touches[0].clientY
        triangulate(mouseX - canvasRect[0], mouseY - canvasRect[1])
    }
    //
    if (page == 3) {
        if (mouseDown == true) { return }
        rotateBrain(ev)        
    } 
})

canvas.addEventListener('touchend', (ev) => {
    deltaX = 0
    deltaY = 0
    mouseDown = false
})

canvas.addEventListener('click', (ev) => {
    if (page == 2) {
        triangulate(mouseX - canvasRect[0], mouseY - canvasRect[1])
    }
})

canvas.addEventListener('mousedown', (ev) => {
    mouseX = ev.clientX
    mouseY = ev.clientY
    if (page == 2) { mouseDown = true }
    if (page == 3) { mouseDown = true }
})

canvas.addEventListener('mousemove', (ev) => {
    if (!mouseDown) { return }
    //
    if (page == 2) {
        mouseX = ev.clientX
        mouseY = ev.clientY
        triangulate(mouseX - canvasRect[0], mouseY - canvasRect[1])
    }
    // 
    if (page == 3) {
        rotateBrain(ev)        
    }         
})

canvas.addEventListener('mouseup', (ev) => {
    mouseDown = false
})

// CANVAS 3D EVENT LISTENERS

canvas3d.addEventListener('touchstart', (ev) => {
    console.log('touchstart', ev)
    mouseX = ev.touches[0].clientX
    mouseY = ev.touches[0].clientY
    mouseDown = true
})

canvas3d.addEventListener('touchmove', (ev) => {    
    if (mouseDown == false) { return }
    rotateBrain(ev)         
})

canvas3d.addEventListener('touchend', (ev) => {
    deltaX = 0
    deltaY = 0
    mouseDown = false
})

canvas3d.addEventListener('mousedown', (ev) => {
    mouseX = ev.clientX
    mouseY = ev.clientY
    if (page == 3) { mouseDown = true }
})

canvas3d.addEventListener('mousemove', (ev) => {
    if (!mouseDown) { return }
    if (page == 3) { rotateBrain(ev) }     
})

canvas3d.addEventListener('mouseup', (ev) => {
    mouseDown = false
})
// END OF CANVAS 3D

canvas.addEventListener('wheel', (ev) => {
    if (page == 2) {
        mouseX = ev.clientX - canvasRect[0]
        mouseY = ev.clientY - canvasRect[1]
        // tra
        if (cursorInRect(mouseX, mouseY, traRect)) {
            if (ev.deltaY > 0) {
                traNum++
                if (traNum > 36) { traNum = 36 }
            } else {
                traNum--
                if (traNum < 7) { traNum = 7 }
            }
            corYpos = traNum / 43.5
            sagYpos = traNum / 43.5
            // update
            updateTriangulation()
        }
        // cor
        if (cursorInRect(mouseX, mouseY, corRect)) {
            if (ev.deltaY > 0) {
                corNum++
                if (corNum > 40) { corNum = 40 }
            } else {
                corNum--
                if (corNum < 3) { corNum = 3 }
            }
            traYpos = corNum / 43.5
            sagXpos = corNum / 43.5
            // update
            updateTriangulation()
        }
        // sag
        if (cursorInRect(mouseX, mouseY, sagRect)) {
            if (ev.deltaY > 0) {
                sagNum++
                if (sagNum > 36) { sagNum = 36 }
            } else {
                sagNum--
                if (sagNum < 7) { sagNum = 7 }
            } 
            traXpos = sagNum / 43.5
            corXpos = sagNum / 43.5 
            // update
            updateTriangulation()                                           
        }
    }
    if (page == 4) {
        if (pdfState.zoom != 1) {
            if (ev.deltaY > 0) {
                pdfState.offsetY -= pdfState.incOffsetY
                if (pdfState.offsetY < -pdfState.maxOffsetY) { pdfState.offsetY = -pdfState.maxOffsetY }
            } else {
                pdfState.offsetY += pdfState.incOffsetY
                if (pdfState.offsetY > 0) { pdfState.offsetY = 0 }            
            }
            renderPdf()
        }
    }
})

footer.addEventListener('click', (ev) => {
    if (page == 2) {
        if (cursorInRect(ev.offsetX, ev.offsetY, button1Rect)) { reportButtonClick() }
        if (cursorInRect(ev.offsetX, ev.offsetY, button2Rect)) { page2dButtonClick() }
        if (cursorInRect(ev.offsetX, ev.offsetY, button3Rect)) { page3dButtonClick() }
        if (cursorInRect(ev.offsetX, ev.offsetY, button4Rect)) { traButtonClick() }
        if (cursorInRect(ev.offsetX, ev.offsetY, button5Rect)) { corButtonClick() } 
        if (cursorInRect(ev.offsetX, ev.offsetY, button6Rect)) { sagButtonClick() }         
    }
    if (page == 3) {
        if (cursorInRect(ev.offsetX, ev.offsetY, button1Rect)) { reportButtonClick() }
        if (cursorInRect(ev.offsetX, ev.offsetY, button2Rect)) { page2dButtonClick() }
        if (cursorInRect(ev.offsetX, ev.offsetY, button3Rect)) { page3dButtonClick() }
        if (cursorInRect(ev.offsetX, ev.offsetY, button4Rect)) { playButtonClick() }
        if (cursorInRect(ev.offsetX, ev.offsetY, button5Rect)) { reverseButtonClick() } 
        if (cursorInRect(ev.offsetX, ev.offsetY, button6Rect)) { flipButtonClick() }         
    }
    if (page == 4) {
        if (cursorInRect(ev.offsetX, ev.offsetY, button1Rect)) { reportButtonClick() }
        if (cursorInRect(ev.offsetX, ev.offsetY, button2Rect)) { page2dButtonClick() }
        if (cursorInRect(ev.offsetX, ev.offsetY, button3Rect)) { page3dButtonClick() } 
        if (cursorInRect(ev.offsetX, ev.offsetY, button5Rect)) { upButtonClick() } 
        if (cursorInRect(ev.offsetX, ev.offsetY, button6Rect)) { downButtonClick() }      
    }  
})

header.addEventListener('click', (ev) => {
    if (cursorInRect(ev.offsetX, ev.offsetY, button0Rect)) { homeButtonClick() }
    if (cursorInRect(ev.offsetX, ev.offsetY, lionRect)) { lionButtonClick() }
})

function upButtonClick() {
    pdfState.offsetY -= pdfState.incOffsetY
    if (pdfState.offsetY < 0) { pdfState.offsetY = 0 }
    renderPdf()
}

function downButtonClick() {
    pdfState.offsetY += pdfState.incOffsetY
    if (pdfState.offsetY > pdfState.maxOffsetY) { pdfState.offsetY = pdfState.maxOffsetY }
    renderPdf()
}

function reportButtonClick() {
    stopTick = true     // stop animation
    if ((page == 2) || (page == 3)) { 
        console.log('reportButtonClick')
        createPage4()
    }
}

function homeButtonClick() {
    stopTick = true     // stop animation
    if (isMobile) { fscreen.exitFullscreen(main) }
    brainZrotation()    // reset to Z orientation
    mode = 'init'
    createPage1()
}

function lionButtonClick() {
    console.log('lionButtonClick')
    if (bkg == 1) {
        bkg = 2
        document.body.style.backgroundImage = "url('/img/background2.jpg')"
    } else {
        bkg = 1
        document.body.style.backgroundImage = "url('/img/background1.jpg')"
    }
    if (page == 2) { createPage2() }
    if (page == 3) { createPage3() }
    if (page == 4) { createPage4() }
}

function page2dButtonClick() {
    stopTick = true     // stop animation
    if ((page == 3) || (page == 4)) { 
        console.log('page2dButtonClick')
        createPage2()
    }
}

function page3dButtonClick() {
    if ((page == 2) || (page == 4)) { 
        if (meshLoaded) {
            console.log('page3dButtonClick')
            createPage3()
        }
    }
}

function traButtonClick() {
    mode = 'tra'
    updateHeader()
    updateFooter(2)
    updateTriangulation()
}

function corButtonClick() {
    mode = 'cor'
    updateHeader()
    updateFooter(2)
    updateTriangulation()
}

function sagButtonClick() {
    mode = 'sag'
    updateHeader()
    updateFooter(2)
    updateTriangulation()
}

function playButtonClick() {
    if (cineMode == true) {
        cineMode = false
    } else {
        cineMode = true
    }
    updateHeader()
    updateFooter(3)
}

function reverseButtonClick() {
    rotationSpeed = -1 * rotationSpeed
}

function flipButtonClick() {
    if (cineZrotation == true) {
        rotationSpeed = -1 * rotationSpeed
        brainYrotation()
    } else {
        rotationSpeed = -1 * rotationSpeed
        brainZrotation()
    }
}

function createPage1() {
    // remove any previous meshes from the scene
    if (brainCortex != null) { scene.remove(brainCortex) }
    if (brainWireframe != null) { scene.remove(brainWireframe) }
    if (brainMTV50 != null) { scene.remove(brainMTV50) }
    if (brainMTV57 != null) { scene.remove(brainMTV57) }
    if (brainMTV67 != null) { scene.remove(brainMTV67) }
    if (brainMTV85 != null) { scene.remove(brainMTV85) }
    if (brainMTV90 != null) { scene.remove(brainMTV90) }
    // remove labels from display
    q1rect = [0,0,0,0]
    q2rect = [0,0,0,0]
    q3rect = [0,0,0,0]
    q4rect = [0,0,0,0] 
    // clear the header
    ctxHeader.clearRect(0, 0, headerRect[2], headerRect[3])
    // clear the footer
    ctxFooter.clearRect(0, 0, footerRect[2], footerRect[3])
    // clear the canvas
    ctx2d.clearRect(0, 0, canvasRect[2], canvasRect[3])
    //
    canvas.hidden = true
    canvas3d.hidden = true
    // start triangulation in center of brain
    initSlicePositions()
    // start Z-cine CW in combo mode (program init values)
    rotationSpeed = 0.01
    cineMode = true
    cineZrotation = true
    zoomMode = 0
    stopTick = true // don't animate at this time
    // set page number
    page = 1
    //
    case1.style.width = Math.floor(0.5 * mainRect[2]) + 'px'
    case1.style.textAlign = 'center'
    case1.style.left = Math.floor(mainRect[0] + 0.25 * mainRect[2]) + 'px'
    case1.style.top = Math.floor(mainRect[3] / 2 - 70) + 'px'
    case1.style.display = 'block'
    case1.style.visibility = 'visible'
    //
    case2.style.width = Math.floor(0.5 * mainRect[2]) + 'px'
    case2.style.textAlign = 'center'
    case2.style.left = Math.floor(mainRect[0] + 0.25 * mainRect[2]) + 'px'
    case2.style.top = Math.floor(mainRect[3] / 2) + 'px'
    case2.style.display = 'block'
    case2.style.visibility = 'visible'
    //
    case3.style.width = Math.floor(0.5 * mainRect[2]) + 'px'
    case3.style.textAlign = 'center'
    case3.style.left = Math.floor(mainRect[0] + 0.25 * mainRect[2]) + 'px'
    case3.style.top = Math.floor(mainRect[3] / 2 + 70) + 'px'
    case3.style.display = 'block'
    case3.style.visibility = 'visible'
    //
    pagetitle.style.width = Math.floor(0.8 * mainRect[2]) + 'px'
    pagetitle.style.textAlign = 'center'
    pagetitle.style.left = Math.floor(mainRect[0] + 0.1 * mainRect[2]) + 'px'
    pagetitle.style.top = Math.floor(0.25 * mainRect[3]) + 'px'
    pagetitle.style.display = 'block'
    pagetitle.style.visibility = 'visible'
    pagetitle.style.fontStyle = 'oblique'
    //
    redlionLogo.style.display = 'block'
    redlionLogo.style.visibility = 'visible'
    redlionLogo.style.position = 'absolute'
    redlionLogo.style.width = '64px'
    redlionLogo.style.height = '64px'
    redlionLogo.style.left = Math.floor(mainRect[0] + 0.5 * mainRect[2] - 32) + 'px'
    redlionLogo.style.top = Math.floor(0.25 * mainRect[3] - 70) + 'px'
    redlionLogo.style.zIndex = '1'
    //
    help1.style.width = Math.floor(0.5 * mainRect[2]) + 'px'
    help1.style.textAlign = 'center'
    help1.style.left = Math.floor(mainRect[0] + 0.25 * mainRect[2]) + 'px'
    help1.style.top = Math.floor(mainRect[3] / 2 - 140) + 'px'
    help1.style.display = 'block'
    help1.style.visibility = 'visible'
}

function createPage2() {
    // hide page1 elements
    redlionLogo.style.visibility = 'hidden'
    pagetitle.style.visibility = 'hidden'
    case1.style.visibility = 'hidden'
    case2.style.visibility = 'hidden'
    case3.style.visibility = 'hidden'
    help1.style.visibility = 'hidden'
    //
    page = 2
    if ((mode != 'tra') && (mode != 'cor') && (mode != 'sag')) { mode = 'tra'}
    // clear canvas
    ctx2d.clearRect(0, 0, canvasRect[2], canvasRect[3])
    if (bkg == 1) {
        ctx2d.drawImage(background1, canvasRect[0], canvasRect[1], canvasRect[2], canvasRect[3], 0, 0, canvasRect[2], canvasRect[3])
    } else {
        ctx2d.drawImage(background2, canvasRect[0], canvasRect[1], canvasRect[2], canvasRect[3], 0, 0, canvasRect[2], canvasRect[3])
    }
    //
    updateHeader()
    //
    updateFooter(2)
    // select the canvas to display
    canvas.hidden = false
    canvas3d.hidden = true
    // hide webgl text
    q1text.style.visibility = 'hidden'
    q2text.style.visibility = 'hidden'
    q3text.style.visibility = 'hidden'
    q4text.style.visibility = 'hidden'
    //
    updateTriangulation()  
}

function updateHeader() {
    // clear the header
    ctxHeader.clearRect(0, 0, headerRect[2], headerRect[3])
    if (bkg == 1) {
        ctxHeader.drawImage(background1, headerRect[0], headerRect[1], headerRect[2], headerRect[3], 0, 0, headerRect[2], headerRect[3])
        ctxHeader.drawImage(homeButtonGray, button0Rect[0], button0Rect[1], button0Rect[2], button0Rect[3]) 
        ctxHeader.fillStyle = "#323232"
    } else {
        ctxHeader.drawImage(background2, headerRect[0], headerRect[1], headerRect[2], headerRect[3], 0, 0, headerRect[2], headerRect[3])
        ctxHeader.drawImage(homeButtonGreen, button0Rect[0], button0Rect[1], button0Rect[2], button0Rect[3]) 
        ctxHeader.fillStyle = "#ffffff"
    }
    // red lion
    ctxHeader.drawImage(redlionLogo, 0, 0, 276, 250, lionRect[0], lionRect[1], lionRect[2], lionRect[3]) 
    // page title
    ctxHeader.font = 'oblique 24px comic sans'
    ctxHeader.textAlign="center" 
    ctxHeader.fillText(pagetitle.innerHTML, headerRect[2] / 2, headerRect[3] - 10) 
    // add case label to header
    ctxHeader.textAlign="right" 
    if (caseNum == 1) { ctxHeader.fillText(case1.innerHTML, headerRect[0] + headerRect[2] - mainRect[0] - 10, headerRect[1] + 28) }
    if (caseNum == 2) { ctxHeader.fillText(case2.innerHTML, headerRect[0] + headerRect[2] - mainRect[0] - 10, headerRect[1] + 28) }
    if (caseNum == 3) { ctxHeader.fillText(case3.innerHTML, headerRect[0] + headerRect[2] - mainRect[0] - 10, headerRect[1] + 28) }
}

function updateFooter(pageNumber) {
    // clear the footer
    ctxFooter.clearRect(0, 0, footerRect[2], footerRect[3]) 
    if (bkg == 1) {
        ctxFooter.drawImage(background1, footerRect[0], footerRect[1], footerRect[2], footerRect[3], 0, 0, footerRect[2], footerRect[3])
    } else {
        ctxFooter.drawImage(background2, footerRect[0], footerRect[1], footerRect[2], footerRect[3], 0, 0, footerRect[2], footerRect[3])
    }
    // draw 1px border around footer
	//ctxFooter.lineWidth = 1
	//ctxFooter.strokeStyle = "#323232"
	//ctxFooter.setLineDash([2, 0]) // [dots, spaces] if spaces = 0 then line is solid
	//ctxFooter.beginPath()
	//ctxFooter.moveTo(0, 0)
	//ctxFooter.lineTo(footerRect[2] - 1, 0)
    //ctxFooter.lineTo(footerRect[2] - 1, footerRect[3] - 1)
    //ctxFooter.lineTo(0, footerRect[3] - 1)
    //ctxFooter.lineTo(0, 0)
	//ctxFooter.stroke()
    //
    if (pageNumber == 2) {
        if (bkg == 1) {
            ctxFooter.drawImage(reportButtonGray, button1Rect[0], button1Rect[1], button1Rect[2], button1Rect[3])  
            ctxFooter.drawImage(page2dButtonWhite, button2Rect[0], button2Rect[1], button2Rect[2], button2Rect[3])   
            ctxFooter.drawImage(page3dButtonGray, button3Rect[0], button3Rect[1], button3Rect[2], button3Rect[3])
        } else {
            ctxFooter.drawImage(reportButtonGreen, button1Rect[0], button1Rect[1], button1Rect[2], button1Rect[3])  
            ctxFooter.drawImage(page2dButtonWhite, button2Rect[0], button2Rect[1], button2Rect[2], button2Rect[3])   
            ctxFooter.drawImage(page3dButtonGreen, button3Rect[0], button3Rect[1], button3Rect[2], button3Rect[3])
        }
        // tra button
        if (mode == 'tra') {
            ctxFooter.drawImage(traButtonWhite, button4Rect[0], button4Rect[1], button4Rect[2], button4Rect[3])
        } else {
            if (bkg == 1) { ctxFooter.drawImage(traButtonGray, button4Rect[0], button4Rect[1], button4Rect[2], button4Rect[3]) }
            if (bkg == 2) { ctxFooter.drawImage(traButtonGreen, button4Rect[0], button4Rect[1], button4Rect[2], button4Rect[3]) }
        }
        // cor button
        if (mode == 'cor') {
            ctxFooter.drawImage(corButtonWhite, button5Rect[0], button5Rect[1], button5Rect[2], button5Rect[3])
        } else {
            if (bkg == 1) { ctxFooter.drawImage(corButtonGray, button5Rect[0], button5Rect[1], button5Rect[2], button5Rect[3]) }
            if (bkg == 2) { ctxFooter.drawImage(corButtonGreen, button5Rect[0], button5Rect[1], button5Rect[2], button5Rect[3]) }
        }
        // sag button
        if (mode == 'sag') {
            ctxFooter.drawImage(sagButtonWhite, button6Rect[0], button6Rect[1], button6Rect[2], button6Rect[3]) 
        } else {
            if (bkg == 1) { ctxFooter.drawImage(sagButtonGray, button6Rect[0], button6Rect[1], button6Rect[2], button6Rect[3]) }
            if (bkg == 2) { ctxFooter.drawImage(sagButtonGreen, button6Rect[0], button6Rect[1], button6Rect[2], button6Rect[3]) }
        }
    }
    if (pageNumber == 3) {
        if (bkg == 1) {
            ctxFooter.drawImage(reportButtonGray, button1Rect[0], button1Rect[1], button1Rect[2], button1Rect[3])  
            ctxFooter.drawImage(page2dButtonGray, button2Rect[0], button2Rect[1], button2Rect[2], button2Rect[3])   
            ctxFooter.drawImage(page3dButtonWhite, button3Rect[0], button3Rect[1], button3Rect[2], button3Rect[3])
        } else {
            ctxFooter.drawImage(reportButtonGreen, button1Rect[0], button1Rect[1], button1Rect[2], button1Rect[3])  
            ctxFooter.drawImage(page2dButtonGreen, button2Rect[0], button2Rect[1], button2Rect[2], button2Rect[3])   
            ctxFooter.drawImage(page3dButtonWhite, button3Rect[0], button3Rect[1], button3Rect[2], button3Rect[3])
        }
        // play/stop button
        if (cineMode == true) {
            if (bkg == 1) { ctxFooter.drawImage(stopButtonGray, button4Rect[0], button4Rect[1], button4Rect[2], button4Rect[3]) }
            if (bkg == 2) { ctxFooter.drawImage(stopButtonGreen, button4Rect[0], button4Rect[1], button4Rect[2], button4Rect[3]) }
        } else {
            if (bkg == 1) { ctxFooter.drawImage(playButtonGray, button4Rect[0], button4Rect[1], button4Rect[2], button4Rect[3]) }
            if (bkg == 2) { ctxFooter.drawImage(playButtonGreen, button4Rect[0], button4Rect[1], button4Rect[2], button4Rect[3]) }
        }
        // reverse button
        if (bkg == 1) { ctxFooter.drawImage(reverseButtonGray, button5Rect[0], button5Rect[1], button5Rect[2], button5Rect[3]) }
        if (bkg == 2) { ctxFooter.drawImage(reverseButtonGreen, button5Rect[0], button5Rect[1], button5Rect[2], button5Rect[3]) }
        // flip button    
        if (bkg == 1) { ctxFooter.drawImage(flipButtonGray, button6Rect[0], button6Rect[1], button6Rect[2], button6Rect[3]) }
        if (bkg == 2) { ctxFooter.drawImage(flipButtonGreen, button6Rect[0], button6Rect[1], button6Rect[2], button6Rect[3]) }
    }

    if (pageNumber == 4) {
        if (bkg == 1) {
            ctxFooter.drawImage(reportButtonWhite, button1Rect[0], button1Rect[1], button1Rect[2], button1Rect[3])  
            ctxFooter.drawImage(page2dButtonGray, button2Rect[0], button2Rect[1], button2Rect[2], button2Rect[3])   
            ctxFooter.drawImage(page3dButtonGray, button3Rect[0], button3Rect[1], button3Rect[2], button3Rect[3])       
            ctxFooter.drawImage(upButtonGray, button5Rect[0], button5Rect[1], button5Rect[2], button5Rect[3])
            ctxFooter.drawImage(downButtonGray, button6Rect[0], button6Rect[1], button6Rect[2], button6Rect[3])
        } else {
            ctxFooter.drawImage(reportButtonWhite, button1Rect[0], button1Rect[1], button1Rect[2], button1Rect[3])  
            ctxFooter.drawImage(page2dButtonGreen, button2Rect[0], button2Rect[1], button2Rect[2], button2Rect[3])   
            ctxFooter.drawImage(page3dButtonGreen, button3Rect[0], button3Rect[1], button3Rect[2], button3Rect[3])       
            ctxFooter.drawImage(upButtonGreen, button5Rect[0], button5Rect[1], button5Rect[2], button5Rect[3])
            ctxFooter.drawImage(downButtonGreen, button6Rect[0], button6Rect[1], button6Rect[2], button6Rect[3])
        }
    }
}

function createPage3() {
    //
    page = 3
    // clear canvas
    ctx2d.clearRect(0, 0, canvasRect[2], canvasRect[3])
    if (bkg == 1) { ctx2d.drawImage(background1, canvasRect[0], canvasRect[1], canvasRect[2], canvasRect[3], 0, 0, canvasRect[2], canvasRect[3]) }
    if (bkg == 2) { ctx2d.drawImage(background2, canvasRect[0], canvasRect[1], canvasRect[2], canvasRect[3], 0, 0, canvasRect[2], canvasRect[3]) }
    // create the header
    updateHeader()
    // create the footer
    updateFooter(3)
    // select the canvas to display
    //canvas.hidden = true
    canvas3d.hidden = false
    // update size of renderer
    renderer.setSize(canvas3d.width, canvas3d.height)
    // the canvas3d quadrants
    q1rect = [canvas3dRect[0], canvas3dRect[1], canvas3dRect[2] / 2, canvas3dRect[3] / 2]
    q2rect = [canvas3dRect[0] + canvas3dRect[2] / 2, canvas3dRect[1], canvas3dRect[2] / 2, canvas3dRect[3] / 2]
    q3rect = [canvas3dRect[0], canvas3dRect[1] + canvas3dRect[3] / 2, canvas3dRect[2] / 2, canvas3dRect[3] / 2]
    q4rect = [canvas3dRect[0] + canvas3dRect[2] / 2, canvas3dRect[1] + canvas3dRect[3] / 2, canvas3dRect[2] / 2, canvas3dRect[3] / 2]
    // font sizes for canvas labels 
    q1text.style.fontSize = canvas3dRect[2] / 40 + 'px'
    q2text.style.fontSize = canvas3dRect[2] / 40 + 'px'
    q3text.style.fontSize = canvas3dRect[2] / 40 + 'px'
    q4text.style.fontSize = canvas3dRect[2] / 40 + 'px' 
    //
    /*
    zoomMode = 0
    quadrant = [-1, 1, 1, -1]
    q1text.style.top = canvas3dRect[1] + canvas3dRect[3] / 2 - 10 + 'px'
    q2text.style.top = canvas3dRect[1] + canvas3dRect[3] / 2 - 10 + 'px'
    q3text.style.top = canvas3dRect[1] + canvas3dRect[3] - 10 + 'px'
    q4text.style.top = canvas3dRect[1] + canvas3dRect[3] - 10 + 'px'
    q1text.style.display = 'block'
    q2text.style.display = 'block'
    q3text.style.display = 'block'
    q4text.style.display = 'block'
    */
    //
    if (bkg == 1) {
        texture1.offset = new Vector2(canvas3dRect[0] / background1.width, 1 - (canvas3dRect[1] + canvas3dRect[3]) / background1.height)
        texture1.repeat = new Vector2(canvas3dRect[2] / background1.width, canvas3dRect[3] / background1.height)
        texture1.needsUpdate = true
        scene.background = texture1
    } else {
        texture2.offset = new Vector2(canvas3dRect[0] / background2.width, 1 - (canvas3dRect[1] + canvas3dRect[3]) / background2.height)
        texture2.repeat = new Vector2(canvas3dRect[2] / background2.width, canvas3dRect[3] / background2.height)
        texture2.needsUpdate = true
        scene.background = texture2
    }

    // update camera
    camera.left = quadrant[0]
    camera.top = quadrant[1]
    camera.right = quadrant[2]
    camera.bottom = quadrant[3]
    camera.updateProjectionMatrix()
    //
    initCombo()

    zoomMode = 1
    quadrant = [-1, 1, 0, 0]
    q1text.style.top = canvas3dRect[1] + canvas3dRect[3] - 10 + 'px'
    q1text.style.display = 'block'
    q2text.style.display = 'none'
    q3text.style.display = 'none'
    q4text.style.display = 'none'
    camera.left = quadrant[0]
    camera.top = quadrant[1]
    camera.right = quadrant[2]
    camera.bottom = quadrant[3]
    camera.updateProjectionMatrix()

    stopTick = false
    tick()
}

function createPage4() {
    //
    page = 4
    // clear canvas (reset width and height cures pdf rendering issue caused by text rendering in page2d)
    canvas.width = 0
    canvas.height = 0
    canvas.width = canvasRect[2]
    canvas.height = canvasRect[3]
    ctx2d.clearRect(0, 0, canvasRect[2], canvasRect[3])
    // create the header
    updateHeader()
    // create the footer
    updateFooter(4)
    // select the canvas to display
    canvas.hidden = false
    canvas3d.hidden = true
    // hide webgl text
    q1text.style.visibility = 'hidden'
    q2text.style.visibility = 'hidden'
    q3text.style.visibility = 'hidden'
    q4text.style.visibility = 'hidden'
    // get pdf and display
    switch(caseNum) {
        case 1: pdfjsLib.getDocument('../pdf/case1/Anon_Deerfield_Report1.pdf').then((pdf) => {      
                    pdfState.pdf = pdf
                    renderPdf()
                })
                break
        case 2: pdfjsLib.getDocument('../pdf/case2/Anon_Deerfield_Report2.pdf').then((pdf) => {      
                    pdfState.pdf = pdf
                    renderPdf()
                })
                break
        case 3: pdfjsLib.getDocument('../pdf/case3/Anon_Deerfield_Report3.pdf').then((pdf) => {      
                    pdfState.pdf = pdf
                    renderPdf()
                })
                break
        default:
    }
}

function renderPdf() {
    pdfState.pdf.getPage(pdfState.currentPage).then((page) => {

        var viewport = page.getViewport(1)
        var scaleX = canvasRect[2] / viewport.width
        viewport = page.getViewport(scaleX)

        // offsetY controls
        pdfState.incOffsetY = Math.ceil((viewport.height - canvasRect[2]) / 4)
        if (pdfState.incOffsetY < 0) { pdfState.incOffsetY = 0 }
        pdfState.maxOffsetY = viewport.height - canvasRect[2]
        if (pdfState.maxOffsetY < 0) { pdfState.maxOffsetY = 0 }

        // transfor the viewport
        viewport.transform = [scaleX, 0, 0, -scaleX, pdfState.offsetX, viewport.height - pdfState.offsetY]

        // render the page
        page.render({
            canvasContext: ctx2d,
            viewport: viewport
        })
    });    
}

/**
* Animate (tick function)
*/
const clock = new THREE.Clock()
let previousTime = 0
//const tick = () =>
function tick()
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime
    // roate the meshes
    if (cineMode) {
        if (cineZrotation) {
            brainCortex.rotation.z += rotationSpeed
            brainMTV90.rotation.z += rotationSpeed
            brainMTV85.rotation.z += rotationSpeed
            brainWireframe.rotation.z += rotationSpeed
            brainMTV57.rotation.z += rotationSpeed
            brainMTV67.rotation.z += rotationSpeed  
        } else {
            brainCortex.rotation.y += rotationSpeed
            brainMTV90.rotation.y += rotationSpeed
            brainMTV85.rotation.y += rotationSpeed
            brainWireframe.rotation.y += rotationSpeed
            brainMTV57.rotation.y += rotationSpeed
            brainMTV67.rotation.y += rotationSpeed  
        }
            
    }
    // Render
    renderer.render(scene, camera)
    // Call tick again on the next frame
    if (stopTick == false) { window.requestAnimationFrame(tick) }
}

function initCombo() {
    //
    brainZrotation()
    rotationSpeed = 0.01
    cineMode = true
    zoomMode = 0
    //
    brainMTV50.visible = false
    // Quadrant 1
    brainCortex.visible = true
    brainCortex.position.set(-0.5, 0.5, 0.0)
    brainCortex.rotation.z = 0
    // Quadrant 2
    brainWireframe.visible = true
    brainWireframe.position.set(0.5, 0.5, 0.0)
    brainWireframe.rotation.z = 0
    //
    brainMTV85.visible = true
    brainMTV85.material.wireframe = true
    brainMTV85.position.set(0.5, 0.5, 0.0)
    brainMTV85.rotation.z = 0
    //
    brainMTV90.visible = true
    brainMTV90.position.set(0.5, 0.5, 0.0)
    brainMTV90.rotation.z = 0
    // Quadrant 3
    brainMTV57.visible = true
    brainMTV57.position.set(-0.5, -0.5, 0.0)
    brainMTV57.rotation.z = 0
    // Quadrant 4
    brainMTV67.visible = true
    brainMTV67.position.set(0.5, -0.5, 0.0) 
    brainMTV67.rotation.z = 0
    // labels
    q1text.style.left = canvas3dRect[0] + 10 + 'px'
    q1text.style.top = canvas3dRect[1] + canvas3dRect[3] / 2 - 10 + 'px'
    q1text.style.width = canvas3dRect[2] / 4 + 'px'
    q1text.style.textAlign = 'left'
    q1text.style.visibility = 'visible'
    q1text.style.hidden = false
    q1text.style.display = 'block'
    q1text.style.color = '#ffffff'
    //
    q2text.style.left = canvas3dRect[0] + canvas3dRect[2] / 2 - 10 + 'px'
    q2text.style.top = canvas3dRect[1] + canvas3dRect[3] / 2 - 10 + 'px'
    q2text.style.width = canvas3dRect[2] / 2 + 'px'
    q2text.style.textAlign = 'right'
    q2text.style.visibility = 'visible'
    q2text.style.display = 'block'
    q2text.style.color = '#ffffff'
    //
    q3text.style.left = canvas3dRect[0] + 10 + 'px'
    q3text.style.top = canvas3dRect[1] + canvas3dRect[3] - 10 + 'px'
    q3text.style.width = canvas3dRect[2] / 2  + 'px'
    q3text.style.textAlign = 'left'
    q3text.style.visibility = 'visible'
    q3text.style.display = 'block'
    q3text.style.color = '#ffffff'
    //
    q4text.style.left = canvas3dRect[0] + canvas3dRect[2] / 2 - 10 + 'px'
    q4text.style.top = canvas3dRect[1] + canvas3dRect[3] - 10 + 'px'
    q4text.style.width = canvas3dRect[2] / 2 + 'px'
    q4text.style.textAlign = 'right'
    q4text.style.visibility = 'visible'
    q4text.style.display = 'block'
    q4text.style.color = '#ffffff'
    // lights
    ambientLight.intensity = 1.0
    spotLight.intensity = 1.0
}

canvas3d.addEventListener('dblclick', (ev) => {
    if (page == 3) {
        console.log('page 3 dblclick')
        dblclickEvents(ev)
    }
})

/**
* Load all the brain MESH data
*/
function loadBrainMeshData() {

    // background image for canvas3d
    texture1 = new THREE.TextureLoader().load( '/img/background1.jpg' )
    texture2 = new THREE.TextureLoader().load( '/img/background2.jpg' )

    // case folder
    let caseFolder = 'case' + caseNum.toString()
    // default material
    //const defaultMaterial = new THREE.MeshBasicMaterial()
    // Load the MTV wireframe brain
    gltfLoader.load(
        '/gltf/' + caseFolder + '/wireframe.gltf',
        (gltf) =>
        {
            // Find the Mesh
            gltf.scene.traverse( (child) => {
                if (child.isMesh) {
                    // set local material 
                    child.material = wireframeMaterial //defaultMaterial
                    // create the brain mesh from the loaded geometry
                    brainWireframe = new THREE.Mesh(child.geometry, child.material)
                    // orient the brain
                    brainWireframe.rotation.z = Math.PI / 2
                    brainWireframe.rotation.x = Math.PI / 2
                    // add the brain to the scene
                    scene.add(brainWireframe)
                }
            })
        }
    )
    // Load the MTV 50 brain
    gltfLoader.load(
        '/gltf/' + caseFolder + '/mtv50.gltf',
        (gltf) =>
        {
            // Find the Mesh
            gltf.scene.traverse( (child) => {
                if (child.isMesh) {
                    // set local material 
                    child.material = mtv50Material //defaultMaterial
                    // create the brain mesh from the loaded geometry
                    brainMTV50 = new THREE.Mesh(child.geometry, child.material)
                    // orient the brain
                    brainMTV50.rotation.z = Math.PI / 2
                    brainMTV50.rotation.x = Math.PI / 2
                    // add the brain to the scene
                    scene.add(brainMTV50)
                }
            })
        }
    )
    // Load the MTV 57 brain
    gltfLoader.load(
        '/gltf/' + caseFolder + '/mtv57.gltf',
        (gltf) =>
        {
            // Find the Mesh
            gltf.scene.traverse( (child) => {
                if (child.isMesh) {
                    // set local material 
                    child.material = mtv57Material //defaultMaterial
                    // create the brain mesh from the loaded geometry
                    brainMTV57 = new THREE.Mesh(child.geometry, child.material)
                    // orient the brain
                    brainMTV57.rotation.z = Math.PI / 2
                    brainMTV57.rotation.x = Math.PI / 2
                    // add the brain to the scene
                    scene.add(brainMTV57)
                }
            })
        }
    )
    // Load the MTV 67 brain
    gltfLoader.load(
        '/gltf/' + caseFolder + '/mtv67.gltf',
        (gltf) =>
        {
            // Find the Mesh
            gltf.scene.traverse( (child) => {
                if (child.isMesh) {
                    // set local material 
                    child.material = mtv67Material //defaultMaterial
                    // create the brain mesh from the loaded geometry
                    brainMTV67 = new THREE.Mesh(child.geometry, child.material)
                    // orient the brain
                    brainMTV67.rotation.z = Math.PI / 2
                    brainMTV67.rotation.x = Math.PI / 2
                    // add the brain to the scene
                    scene.add(brainMTV67)
                }
            })
        }
    )
    // Load the MTV85 brain
    gltfLoader.load(
        '/gltf/' + caseFolder + '/mtv85.gltf',
        (gltf) =>
        {
            // Find the Mesh
            gltf.scene.traverse( (child) => {
                if (child.isMesh) {
                    // set local material 
                    child.material = mtv85Material //defaultMaterial
                    // create the brain mesh from the loaded geometry
                    brainMTV85 = new THREE.Mesh(child.geometry, child.material)
                    // orient the brain
                    brainMTV85.rotation.z = Math.PI / 2
                    brainMTV85.rotation.x = Math.PI / 2
                    // add the brain to the scene
                    scene.add(brainMTV85)
                }
            })
        }
    )
    // Load the MTV90 brain
    gltfLoader.load(
        '/gltf/' + caseFolder + '/mtv90.gltf',
        (gltf) =>
        {
            // Find the Mesh
            gltf.scene.traverse( (child) => {
                if (child.isMesh) {
                    // set local material 
                    child.material = mtv90Material //defaultMaterial
                    // create the brain mesh from the loaded geometry
                    brainMTV90 = new THREE.Mesh(child.geometry, child.material)
                    // orient the brain
                    brainMTV90.rotation.z = Math.PI / 2
                    brainMTV90.rotation.y = 0
                    brainMTV90.rotation.x = Math.PI / 2
                    // add the brain to the scene
                    scene.add(brainMTV90)
                }
            })
        }
    )
    // Load the Cortical Activity brain
    gltfLoader.load(
        '/gltf/' + caseFolder + '/cortex.gltf',
        (gltf) =>
        {
            // Find the Mesh
            gltf.scene.traverse( (child) => {
                if (child.isMesh) {
                    // set local material 
                    child.material = cortexMaterial //defaultMaterial
                    // create the brain mesh from the loaded geometry
                    brainCortex = new THREE.Mesh(child.geometry, child.material)
                    // orient the brain
                    brainCortex.rotation.z = Math.PI / 2
                    brainCortex.rotation.y = 0
                    brainCortex.rotation.x = Math.PI / 2
                    //
                    brainCortex.layers.enableAll()
                    // add the brain to the scene
                    scene.add(brainCortex)
                }
            })
        }
    )
}

function createMeshMaterials() {
        // cortex material (Phong Material)
        cortexMaterial = new THREE.MeshPhongMaterial()
        cortexMaterial.shininess = 0.0
        cortexMaterial.specular = new THREE.Color(0x000000)
        cortexMaterial.emissive = new THREE.Color(0x000000)
        cortexMaterial.color = new THREE.Color(0x888888)
        cortexMaterial.transparent = true
        cortexMaterial.vertexColors = true
        cortexMaterial.reflectivity = 0.5 
        // mtv wireframe material
        wireframeMaterial = new THREE.MeshLambertMaterial()
        wireframeMaterial.wireframe = true 
        wireframeMaterial.color = new THREE.Color(wireframeColor)
        wireframeMaterial.specular = new THREE.Color(0x000000)
        wireframeMaterial.emissive = new THREE.Color(0x000000)
        wireframeMaterial.reflectivity = 0.1
        wireframeMaterial.refractionRatio = 0.0
        // mtv50 material
        mtv50Material = new THREE.MeshLambertMaterial()
        mtv50Material.wireframe = false
        mtv50Material.color = new THREE.Color(mtv50Color)
        mtv50Material.specular = new THREE.Color(0x000000)
        mtv50Material.emissive = new THREE.Color(0x000000)
        mtv50Material.reflectivity = 0.1
        mtv50Material.refractionRatio = 0.0
        // mtv57 material
        mtv57Material = new THREE.MeshLambertMaterial()
        mtv57Material.wireframe = false
        mtv57Material.color = new THREE.Color(mtv57Color)
        mtv57Material.specular = new THREE.Color(0x000000)
        mtv57Material.emissive = new THREE.Color(0x000000)
        mtv57Material.reflectivity = 0.1
        mtv57Material.refractionRatio = 0.0
        // mtv67 material
        mtv67Material = new THREE.MeshLambertMaterial()
        mtv67Material.wireframe = false
        mtv67Material.color = new THREE.Color(mtv67Color)
        mtv67Material.specular = new THREE.Color(0x000000)
        mtv67Material.emissive = new THREE.Color(0x000000)
        mtv67Material.reflectivity = 0.1
        mtv67Material.refractionRatio = 0.0   
        // mtv85 material
        mtv85Material = new THREE.MeshLambertMaterial()
        mtv85Material.wireframe = false
        mtv85Material.color = new THREE.Color(mtv85Color)
        mtv85Material.specular = new THREE.Color(0x000000)
        mtv85Material.emissive = new THREE.Color(0x000000)
        mtv85Material.reflectivity = 0.1
        mtv85Material.refractionRatio = 0.0
        // mtv90 material
        mtv90Material = new THREE.MeshLambertMaterial()
        mtv90Material.wireframe = false
        mtv90Material.color = new THREE.Color(mtv90Color)
        mtv90Material.specular = new THREE.Color(0x000000)
        mtv90Material.emissive = new THREE.Color(0x000000)
        mtv90Material.reflectivity = 0.1
        mtv90Material.refractionRatio = 0.0
}

function cursorInRect(x, y, rect) {	
	//test if x,y location falls within the rectangle
	if ((x >= rect[0]) && (x <= (rect[0] + rect[2])) && (y >= rect[1]) && (y <= (rect[1] + rect[3]))) { 
		return true
	} else {
		return false
	}	
}

function drawCrosshair(xpos, ypos, rect) {
    if (bkg == 1) {
        ctx2d.strokeStyle = '#323232'
    } else {
        ctx2d.strokeStyle = '#ffffff'
    }  
    ctx2d.lineWidth = 2
	//vertical	
	ctx2d.beginPath()
    ctx2d.setLineDash([])
	ctx2d.moveTo(rect[0] + xpos, rect[1] + 5)
	ctx2d.lineTo(rect[0] + xpos, rect[1] + rect[3] - 6)
	ctx2d.stroke()
	// horizontal
	ctx2d.beginPath()
    ctx2d.setLineDash([])
	ctx2d.moveTo(rect[0] + 5, rect[1] + ypos)
	ctx2d.lineTo(rect[0] + rect[2] - 6, rect[1] + ypos)
	ctx2d.stroke()	
    //
    ctx2d.setLineDash([])
}

function triangulate(mouseX, mouseY) {
    // click in tra viewport
    if (cursorInRect(mouseX, mouseY, traRect)) { 
        traXpos = mouseX / xs1
        traYpos = (mouseY - traRect[1]) / xs1
        corXpos = traXpos
        sagXpos = traYpos
        sagNum = Math.floor(44 * traXpos)
        updateTriangulation()
    } 
    // click in cor viewport
    if (cursorInRect(mouseX, mouseY, corRect)) { 
        corXpos = mouseX / xs1
        corYpos = (mouseY - corRect[1]) / xs1
        traXpos = corXpos
        sagYpos = corYpos
        sagNum = Math.floor(44 * corXpos)
        traNum = Math.floor(44 * corYpos)        
        updateTriangulation()
    } 
    // click in sag viewport
    if (cursorInRect(mouseX, mouseY, sagRect)) { 
        sagXpos = mouseX / xs1
        sagYpos = (mouseY - sagRect[1]) / xs1
        traYpos = sagXpos
        corYpos = sagYpos
        corNum = Math.floor(44 * sagXpos)
        traNum = Math.floor(44 * sagYpos) 
        updateTriangulation()
    } 
    // click in navigation viewport
    if (cursorInRect(mouseX, mouseY, navRect)) { 
        navXpos = Math.floor((mouseX - navRect[0]) / xs2)
        navYpos = Math.floor((mouseY - navRect[1]) / xs2)
        if (mode == 'tra') {
            traNum = 10 * navXpos + navYpos + 7
            corYpos = traNum / 43.5
            sagYpos = traNum / 43.5
            updateTriangulation()
        }
        if (mode == 'cor') {
            corNum = 10 * navXpos + navYpos + 7
            traYpos = corNum / 43.5
            sagXpos = corNum / 43.5
            updateTriangulation()
        }
        if (mode == 'sag') {
            sagNum = 10 * navXpos + navYpos + 7
            traXpos = sagNum / 43.5
            corXpos = sagNum / 43.5
            updateTriangulation()
        }
    }
}

function updateTriangulation() {
    // clear canvas
    ctx2d.clearRect(0, 0, canvasRect[2], canvasRect[3])
    if (bkg == 1) {
        ctx2d.drawImage(background1, canvasRect[0], canvasRect[1], canvasRect[2], canvasRect[3], 0, 0, canvasRect[2], canvasRect[3])
        ctx2d.fillStyle = '#323232'
    } else {
        ctx2d.drawImage(background2, canvasRect[0], canvasRect[1], canvasRect[2], canvasRect[3], 0, 0, canvasRect[2], canvasRect[3])
        ctx2d.fillStyle = '#ffffff'
    }    
    // annotation font    
    var fontSize = Math.floor(traRect[2] / 15)
    ctx2d.font = fontSize.toString() + 'px Times'
    // draw big tra
    ctx2d.drawImage(tra, 198 * (traNum % 11), 198 * Math.floor(traNum / 11), 198, 198, traRect[0], traRect[1], traRect[2], traRect[3])
    drawCrosshair(traXpos * xs1, traYpos * xs1, traRect)
    ctx2d.drawImage(colorbarV, 0, 0, 23, 258, traRect[0], traRect[1], 23, xs1)
    // tra annotation
    ctx2d.textAlign = 'left'
    ctx2d.fillText('R', traRect[0] + 0.12 * traRect[2], traRect[1] + 0.1 * traRect[3])
    ctx2d.textAlign = 'right'
    ctx2d.fillText(traNum.toString(), traRect[0] + 0.95 * traRect[2], traRect[1] + 0.1 * traRect[3])
    // draw big cor
    ctx2d.drawImage(cor, 198 * (corNum % 11), 198 * Math.floor(corNum / 11), 198, 198, corRect[0], corRect[1], corRect[2], corRect[3])
    drawCrosshair(corXpos * xs1, corYpos * xs1, corRect)
    // cor annotation
    ctx2d.textAlign = 'left'
    ctx2d.fillText('R', corRect[0] + 0.12 * corRect[2], corRect[1] + 0.2 * corRect[3])
    ctx2d.textAlign = 'right'
    ctx2d.fillText(corNum.toString(), corRect[0] + 0.95 * corRect[2], corRect[1] + 0.2 * corRect[3])
    // draw big sag
    ctx2d.drawImage(sag, 198 * (sagNum % 11), 198 * Math.floor(sagNum / 11), 198, 198, sagRect[0], sagRect[1], sagRect[2], sagRect[3])
    drawCrosshair(sagXpos * xs1, sagYpos * xs1, sagRect)
    // sag annotation
    ctx2d.textAlign = 'left'
    ctx2d.fillText('A', sagRect[0] + 0.12 * sagRect[2], sagRect[1] + 0.2 * sagRect[3])
    ctx2d.textAlign = 'right'
    ctx2d.fillText(sagNum.toString(), sagRect[0] + 0.95 * sagRect[2], sagRect[1] + 0.2 * sagRect[3])
    //
    if (mode == 'tra') { updateNavigation(traNum - 7) }
    if (mode == 'cor') { updateNavigation(corNum - 7) }
    if (mode == 'sag') { updateNavigation(sagNum - 7) }
}

function updateNavigation(sel) {
    if (bkg == 1) {
        ctx2d.strokeStyle = '#323232'
    } else {
        ctx2d.strokeStyle = '#ffffff'
    }  
    let rect = [0, 0, 0, 0]
    for (var i = 0; i < 30; i++) {
        let x0 = 198 * ((7 + i) % 11)
        let y0 = 198 * Math.floor((7 + i) / 11)
        let x1 = 227 + (227 / 3) * Math.floor(i / 9)
        let y1 = (227 / 3) * (i % 9)
        rect = thumbRect[i]
        if (mode == 'tra') { ctx2d.drawImage(tra, x0, y0, 198, 198, rect[0], rect[1], rect[2], rect[3]) }
        if (mode == 'cor') { ctx2d.drawImage(cor, x0, y0, 198, 198, rect[0], rect[1], rect[2], rect[3]) }
        if (mode == 'sag') { ctx2d.drawImage(sag, x0, y0, 198, 198, rect[0], rect[1], rect[2], rect[3]) }
        if (i == sel) {
            ctx2d.lineWidth = 2          
            ctx2d.setLineDash([]) // [dots, spaces] if spaces = 0 then line is solid
            ctx2d.roundRect(rect[0]+1, rect[1]+1, rect[2]-2, rect[3]-2, 10)
	        ctx2d.stroke()	
        }
    }
}

function dblclickEvents(ev) {
    // get coordinate
    if (ev.type == 'touchstart') {
        mouseX = ev.touches[0].clientX
        mouseY = ev.touches[0].clientY
    } else {
        mouseX = ev.clientX
        mouseY = ev.clientY
    }
    if (zoomMode != 0) {
        zoomMode = 0
        quadrant = [-1, 1, 1, -1]
        q1text.style.top = canvas3dRect[1] + canvas3dRect[3] / 2 - 10 + 'px'
        q2text.style.top = canvas3dRect[1] + canvas3dRect[3] / 2 - 10 + 'px'
        q3text.style.top = canvas3dRect[1] + canvas3dRect[3] - 10 + 'px'
        q4text.style.top = canvas3dRect[1] + canvas3dRect[3] - 10 + 'px'
        q1text.style.display = 'block'
        q2text.style.display = 'block'
        q3text.style.display = 'block'
        q4text.style.display = 'block'
    } else {
        // quadrant 1
        if (cursorInRect(mouseX, mouseY, q1rect)) {
            zoomMode = 1
            quadrant = [-1, 1, 0, 0]
            q1text.style.top = canvas3dRect[1] + canvas3dRect[3] - 10 + 'px'
            q1text.style.display = 'block'
            q2text.style.display = 'none'
            q3text.style.display = 'none'
            q4text.style.display = 'none'
        }
        // quadrant 2
        if (cursorInRect(mouseX, mouseY, q2rect)) {
            zoomMode = 2
            quadrant = [0, 1, 1, 0]
            q2text.style.top = canvas3dRect[1] + canvas3dRect[3] - 10 + 'px'
            q1text.style.display = 'none'
            q2text.style.display = 'block'
            q3text.style.display = 'none'
            q4text.style.display = 'none'
        }
        // quadrant 3
        if (cursorInRect(mouseX, mouseY, q3rect)) {
            zoomMode = 3
            quadrant = [-1, 0, 0, -1]
            q1text.style.display = 'none'
            q2text.style.display = 'none'
            q3text.style.display = 'block'
            q4text.style.display = 'none'
        }
        // quadrant 4
        if (cursorInRect(mouseX, mouseY, q4rect)) {
            zoomMode = 4
            quadrant = [0, 0, 1, -1]
            q1text.style.display = 'none'
            q2text.style.display = 'none'
            q3text.style.display = 'none'
            q4text.style.display = 'block'
        }
    }
    // update camera
    camera.left = quadrant[0]
    camera.top = quadrant[1]
    camera.right = quadrant[2]
    camera.bottom = quadrant[3]
    camera.updateProjectionMatrix()
}

/**
* Initialize the Z-rotation cine mode
*/
function brainZrotation () {
    //cineMode = true
    cineZrotation = true
    brainCortex.rotation.z = Math.PI / 2
    brainCortex.rotation.y = 0
    brainCortex.rotation.x = Math.PI / 2
    brainWireframe.rotation.z = Math.PI / 2
    brainWireframe.rotation.y = 0
    brainWireframe.rotation.x = Math.PI / 2
    brainMTV57.rotation.z = Math.PI / 2
    brainMTV57.rotation.y = 0
    brainMTV57.rotation.x = Math.PI / 2
    brainMTV67.rotation.z = Math.PI / 2
    brainMTV67.rotation.y = 0
    brainMTV67.rotation.x = Math.PI / 2
    brainMTV85.rotation.z = Math.PI / 2
    brainMTV85.rotation.y = 0
    brainMTV85.rotation.x = Math.PI / 2
    brainMTV90.rotation.z = Math.PI / 2
    brainMTV90.rotation.y = 0
    brainMTV90.rotation.x = Math.PI / 2
}

/**
* Initialize the Y-rotation cine mode
*/
function brainYrotation() {
    //cineMode  = true
    cineZrotation = false
    brainCortex.rotation.z = Math.PI
    brainCortex.rotation.y = -Math.PI / 2
    brainCortex.rotation.x = 0
    brainWireframe.rotation.z = Math.PI
    brainWireframe.rotation.y = -Math.PI / 2
    brainWireframe.rotation.x = 0
    brainMTV57.rotation.z = Math.PI
    brainMTV57.rotation.y = -Math.PI / 2
    brainMTV57.rotation.x = 0
    brainMTV67.rotation.z = Math.PI
    brainMTV67.rotation.y = -Math.PI / 2
    brainMTV67.rotation.x = 0
    brainMTV85.rotation.z = Math.PI
    brainMTV85.rotation.y = -Math.PI / 2
    brainMTV85.rotation.x = 0
    brainMTV90.rotation.z = Math.PI
    brainMTV90.rotation.y = -Math.PI / 2
    brainMTV90.rotation.x = 0
}

function rotateBrain(ev) {
    // differentitate between mouse & touch
    if (ev.type == "touchmove") {
        deltaX = ev.touches[0].clientX - mouseX
        deltaY = mouseY - ev.touches[0].clientY
        mouseX = ev.touches[0].clientX   
        mouseY = ev.touches[0].clientY 
    } else {
        deltaX = ev.clientX - mouseX
        deltaY = mouseY - ev.clientY
        mouseX = ev.clientX   
        mouseY = ev.clientY   
    }
    if (cineZrotation) { // Z-rotation cine
        brainCortex.rotation.z -= deltaX / 100;
        brainCortex.rotation.x -= deltaY / 100;
        brainWireframe.rotation.z -= deltaX / 100;
        brainWireframe.rotation.x -= deltaY / 100;
        brainMTV57.rotation.z -= deltaX / 100;
        brainMTV57.rotation.x -= deltaY / 100;
        brainMTV67.rotation.z -= deltaX / 100;
        brainMTV67.rotation.x -= deltaY / 100;
        brainMTV85.rotation.z -= deltaX / 100;
        brainMTV85.rotation.x -= deltaY / 100;
        brainMTV90.rotation.z -= deltaX / 100;
        brainMTV90.rotation.x -= deltaY / 100;
    } else { // Y-rotation cine
        brainCortex.rotation.y += deltaX / 100;
        brainCortex.rotation.x -= deltaY / 100;
        brainWireframe.rotation.y += deltaX / 100;
        brainWireframe.rotation.x -= deltaY / 100;
        brainMTV57.rotation.y += deltaX / 100;
        brainMTV57.rotation.x -= deltaY / 100;
        brainMTV67.rotation.y += deltaX / 100;
        brainMTV67.rotation.x -= deltaY / 100;
        brainMTV85.rotation.y += deltaX / 100;
        brainMTV85.rotation.x -= deltaY / 100;
        brainMTV90.rotation.y += deltaX / 100;
        brainMTV90.rotation.x -= deltaY / 100;
    }
}

